// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-building-commissioning-en-js": () => import("./../../../src/pages/building-commissioning.en.js" /* webpackChunkName: "component---src-pages-building-commissioning-en-js" */),
  "component---src-pages-building-commissioning-es-js": () => import("./../../../src/pages/building-commissioning.es.js" /* webpackChunkName: "component---src-pages-building-commissioning-es-js" */),
  "component---src-pages-cfd-modeling-en-js": () => import("./../../../src/pages/cfd-modeling.en.js" /* webpackChunkName: "component---src-pages-cfd-modeling-en-js" */),
  "component---src-pages-cfd-modeling-es-js": () => import("./../../../src/pages/cfd-modeling.es.js" /* webpackChunkName: "component---src-pages-cfd-modeling-es-js" */),
  "component---src-pages-energy-modeling-en-js": () => import("./../../../src/pages/energy-modeling.en.js" /* webpackChunkName: "component---src-pages-energy-modeling-en-js" */),
  "component---src-pages-energy-modeling-es-js": () => import("./../../../src/pages/energy-modeling.es.js" /* webpackChunkName: "component---src-pages-energy-modeling-es-js" */),
  "component---src-pages-energy-retrofits-en-js": () => import("./../../../src/pages/energy-retrofits.en.js" /* webpackChunkName: "component---src-pages-energy-retrofits-en-js" */),
  "component---src-pages-energy-retrofits-es-js": () => import("./../../../src/pages/energy-retrofits.es.js" /* webpackChunkName: "component---src-pages-energy-retrofits-es-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-projects-en-js": () => import("./../../../src/pages/projects.en.js" /* webpackChunkName: "component---src-pages-projects-en-js" */),
  "component---src-pages-projects-es-js": () => import("./../../../src/pages/projects.es.js" /* webpackChunkName: "component---src-pages-projects-es-js" */)
}

